import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../../input.css";
import "./NavigationBar.scss";
import { Link } from "react-router-dom";

const NavigationBar = () => {
  const [t, i18n] = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleFlagClicked = (e) => {
    e.preventDefault();
    const language = e.currentTarget.getAttribute("data-tag");
    i18n
      .changeLanguage(language)
      .then(() => {
        console.log(`Language changed to ${language}`);
      })
      .catch((err) => console.error("Language change failed", err));
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 10);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const FlagLink = ({ tag, image, alt }) => (
    <button
      onClick={handleFlagClicked}
      data-tag={tag}
      className="flex justify-center items-center"
    >
      <img src={`/images/${image}.png`} alt={alt} className="h-5 w-5 m-1" />
    </button>
  );

  const TranslationFlags = ({ isMobile }) => (
    <div
      className={`flex ${
        isMobile
          ? "flex-col items-center space-y-2"
          : "flex-row items-center space-x-2"
      }`}
    >
      <FlagLink tag="pt-BR" image="pt-br" alt="Traduza para Português" />
      <FlagLink tag="en-US" image="en-gb" alt="Translate to English" />
      <FlagLink tag="es" image="es" alt="Traducir al español" />
    </div>
  );

  const MenuOptions = () => (
    <ul
      className={`flex flex-col md:flex-row w-full md:w-auto items-center space-y-4 md:space-y-0 md:space-x-6 md:mr-10`}
    >
      <li>
        <Link className="visited:text-pyramid-5" to="/?section=hero-section">
          {t("2025_menu_home")}
        </Link>
      </li>
      <li>
        <Link
          className="visited:text-pyramid-5"
          to="/?section=services-section"
        >
          {t("2025_menu_services")}
        </Link>
      </li>
      <li>
        <Link
          className="visited:text-pyramid-5"
          to="/?section=success-stories-section"
        >
          {t("2025_menu_success_stories")}
        </Link>
      </li>
      <li>
        <Link
          className="visited:text-pyramid-5"
          to="/?section=approach-section"
        >
          {t("2025_menu_approach")}
        </Link>
      </li>
      <li>
        <Link className="visited:text-pyramid-5" to="/about">
          {t("2025_menu_about")}
        </Link>
      </li>
      <li>
        <Link className="visited:text-pyramid-5" to="/?section=footer-section">
          {t("2025_menu_contact")}
        </Link>
      </li>
    </ul>
  );

  return (
    <nav className="fixed top-0 w-full z-50">
      <div
        className={`h-20 flex items-center justify-between px-6 md:px-40 transition-all duration-1000 ${
          isScrolled
            ? "bg-gradient-radial from-[rgba(19,19,31,0.9)] via-[rgba(1,9,11,0.9)] to-[rgba(19,19,31,0.9)] shadow-lg"
            : "bg-transparent"
        }`}
      >
        <a href="/" className="h-16 flex-shrink-0">
          <img
            src="images/logo-leanit101-slogan.png"
            alt="Logo"
            className="h-full object-contain"
          />
        </a>
        <button
          className="md:hidden text-white focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d={
                isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>
        <div className="hidden md:flex">
          <MenuOptions />
          <TranslationFlags isMobile={false} />
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden bg-vulcan">
          <div className="py-4 text-center">
            <MenuOptions />
            <TranslationFlags isMobile={true} />
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;
