import "../../input.css";

const Policy = ({ text, name }) => {
  return (
    <div>
      <div className="p-[1rem] px-6 md:px-48 bg-vulcan font-Barlow text-left md:py-32 py-5">
        <div className="text-white md:text-5xl text-2xl py-12">{name}</div>
        <div className="text-slate-500 md:text-xl  text-base">{text}</div>
      </div>
    </div>
  );
};

export default Policy;
