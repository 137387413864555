import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../input.css";

import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import WbIncandescentIcon from "@mui/icons-material/WbIncandescent";
import VerifiedIcon from "@mui/icons-material/Verified";
import Testimonial from "./TestimonialsSection/Testimonial";

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const offsetTop = section.offsetTop; // Get the top position of the section
    window.scrollTo({
      top: offsetTop + 400, // Scroll 80px above the section
      behavior: "smooth", // Enable smooth scrolling
    });
  }
};

const SuccessStory = ({
  index,
  title,
  tagline,
  image,
  summary,
  fullContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      id={`success_story-${title}`}
      key={index}
      className="flex flex-col md:p-10 p-[1rem] md:gap-5 rounded-3xl bg-steelGray border-1 border-solid border-gray-700 m-0 mt-6 transition-all drop-shadow-lg"
      style={{
        backgroundImage: `radial-gradient(rgba(11, 166, 218, 0.1), transparent), 
          url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Ctext x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='rgba(255,255,255,0.05)' font-family='ritheous, sans-serif' font-size='13rem' style='text-shadow: 20px 20px 50px rgba(0,0,0,1)'%3E%3C/text%3E%3C/svg%3E")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
      }}
    >
      <div className="flex md:flex-row flex-col">
        <div className="flex flex-col md:w-1/2 w-full text-center ">
          <div className="p-[1px] rounded-3xl shadow-black shadow-lg   bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1">
            <img src={image} alt="" className="rounded-3xl drop-shadow-lg" />
          </div>
          <span className="md:text-4xl py-2 mt-2 text-2xl font-black text-center inline md:hidden pt-4">
            {title}
          </span>
          <p className="text-base text-left text-gray-400 italic md:px-4 md:hidden">
            {tagline}
          </p>
        </div>
        <div className="flex flex-col md:w-1/2 mt-4">
          <div className="flex md:flex-col flex-col md:pl-6 justify-between text-left">
            <p className="md:text-4xl text-2xl font-black text-left px-4 pt-4 hidden">
              {title}
            </p>
            <p className="text-base text-left text-gray-400 px-4 hidden">
              {tagline}
            </p>
            <div className=" md:text-xl text-cyan-300 flex md:flex-row flex-col-reverse md:items-start md:h-full md:justify-between items-center md:gap-0 gap-2">
              <div className="flex flex-col md:flex-row items-center justify-end h-full md:p-4 md:pt-12 pb-3 gap-2 w-full">
                <div className="flex-row flex text-lg">
                  <span className="md:text-lg text-base md:text-left md:leading-9">
                    {summary}
                  </span>
                </div>
              </div>
            </div>

            <button
              onClick={() => {
                if (!isExpanded) {
                  scrollToSection(`success_story-${title}`);
                }
                setIsExpanded(!isExpanded);
              }}
              id="sucess-story-cta-button"
              className="flex justify-center md:pt-16 py-8 "
            >
              <span
                className={`p-1 w-72 shadow-black shadow-lg rounded-full text-base bg-pyramid-10 font-semibold text-white hover:bg-pyramid-12 drop-shadow-lg`}
              >
                {isExpanded
                  ? t("2025_success_stories_collapse")
                  : t("2025_success_stories_read")}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row ">
        {isExpanded && (
          <div
            id={`approach-card-${1}`}
            className="relative justify-center flex flex-col flex-shrink-0 w-full h-full mt-4 p-[1px] rounded-3xl drop-shadow-xl bg-gradient-to-r from-gray-500 to-gray-700"
          >
            <div
              className="relative flex flex-col bg-vulcan space-y-1 text-gray-200 rounded-3xl h-full w-full text-left sm:px-9 sm:py-8 p-6"
              style={{
                backgroundImage: `radial-gradient(rgba(11, 166, 218, 0.1), transparent))`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            >
              {fullContent}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SuccessStoriesSection = () => {
  const { t } = useTranslation();

  const testimonialsSoftplan = [
    {
      name: "Felipe Fusinatto",
      position: t("2025_testimonial_softplan_role_1"),
      company: "Sofplan",
      quote: t("2025_testimonial_softplan_1"),
      imgUrl: "images/Testimonials/fusinatto.jpeg",
    },
    {
      name: "Álvaro Junior",
      position: t("2025_testimonial_softplan_role_2"),
      company: "Sofplan",
      quote: t("2025_testimonial_softplan_2"),
      imgUrl: "images/Testimonials/alvaro.jpeg",
    },
    {
      name: "André Jorge",
      position: t("2025_testimonial_softplan_role_3"),
      company: "Sofplan",
      quote: t("2025_testimonial_softplan_3"),
      imgUrl: "images/Testimonials/andre.jpeg",
    },
    {
      name: "Ícaro Rezende",
      position: t("2025_testimonial_softplan_role_4"),
      company: "Sofplan",
      quote: t("2025_testimonial_softplan_4"),
      imgUrl: "images/Testimonials/icaro.jpeg",
    },
  ];

  const testimonialsValeEnergy = [
    {
      name: "Jeff Pagnutti",
      position: t("testimonial.position_2"),
      company: "Vale",
      quote: t("testimonial.testimonial_2"),
      imgUrl: "images/Testimonials/jeff.jpeg",
    },
    {
      name: "Victor Henrique",
      position: t("testimonial.position_6"),
      company: "Vale",
      quote: t("testimonial.testimonial_6"),
      imgUrl: "images/Testimonials/victor.jpeg",
    },
    {
      name: "Ian Stegmann",
      position: t("testimonial.position_7"),
      company: "Vale",
      quote: t("testimonial.testimonial_7"),
      imgUrl: "images/Testimonials/ian.jpeg",
    },
    {
      name: "Caio Monterazo",
      position: t("testimonial.position_4"),
      company: "Vale",
      quote: t("testimonial.testimonial_4"),
      imgUrl: "images/Testimonials/caio.jpeg",
    },
    {
      name: "Juliana Chagas",
      position: t("testimonial.position_5"),
      company: "Vale",
      quote: t("testimonial.testimonial_5"),
      imgUrl: "images/Testimonials/juliana.jpeg",
    },
  ];

  const stories = [
    {
      title: t("2025_success_stories_brumadinho_title"),
      tagline: t("2025_success_stories_brumadinho_tagline"),
      image: "/images/brumadinho.jpg",
      summary: t("2025_success_stories_brumadinho_summary"),
      fullContent: (
        <>
          <div className="flex flex-row justify-between itens-center">
            <div>
              <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-cyan-300 hover:text-pyramid-19 hover:cursor-default">
                <FilterHdrIcon
                  className="m-2"
                  fontSize="large"
                  aria-label="Account Tree Icon"
                />
                {t("2025_success_stories_challenge")}
              </h4>
            </div>
            <div className="hidden md:flex items-center">
              <a
                href="mailto:contact@leanit101.com"
                className="text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
              >
                <span>{t("2025_success_stories_have_a_challenge")}</span>
              </a>
            </div>
          </div>

          <div>
            <span>{t("2025_success_stories_brumadinho_challenge1")}</span>
            <span>{t("2025_success_stories_brumadinho_challenge2")}</span>
            <span>{t("2025_success_stories_brumadinho_challenge3")}</span>
            <span>{t("2025_success_stories_brumadinho_challenge4")}</span>
          </div>
          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-10 hover:text-orange-600 hover:cursor-default">
              <WbIncandescentIcon
                className="m-2 rotate-180"
                fontSize="large"
                aria-label="Account Tree Icon"
              />
              {t("2025_success_stories_approach")}
            </h4>
          </div>
          <p>
            {t("2025_success_stories_brumadinho_approach_intro1")}
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach_intro2")}
            </span>
            {t("2025_success_stories_brumadinho_approach_intro3")}
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach_intro4")}
            </span>
            {t("2025_success_stories_brumadinho_approach_intro5")}
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach_intro6")}
            </span>
            {t("2025_success_stories_brumadinho_approach_intro7")}
          </p>
          <p className="pt-4 ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach1_title")}
            </span>
            {t("2025_success_stories_brumadinho_approach1_description")}
          </p>
          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach2_title")}
            </span>
            {t("2025_success_stories_brumadinho_approach2_description")}
          </p>
          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach3_title")}
            </span>
            {t("2025_success_stories_brumadinho_approach3_description")}
          </p>
          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach4_title")}
            </span>
            {t("2025_success_stories_brumadinho_approach4_description")}
          </p>
          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_approach5_title")}
            </span>
            {t("2025_success_stories_brumadinho_approach5_description")}
          </p>
          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-1  hover:text-pyramid-3 hover:cursor-default">
              <VerifiedIcon
                className="m-2"
                fontSize="large"
                aria-label="Account Tree Icon"
              />
              {t("2025_success_stories_results")}
            </h4>
          </div>

          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_results1_title")}
            </span>
            {t("2025_success_stories_brumadinho_results1_description")}
          </p>
          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_results2_title")}
            </span>
            {t("2025_success_stories_brumadinho_results2_description")}
          </p>
          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_results3_title")}
            </span>
            {t("2025_success_stories_brumadinho_results3_description")}
          </p>
          <p className="ml-4">
            <span className="font-semibold text-white">
              {t("2025_success_stories_brumadinho_results4_title")}
            </span>
            {t("2025_success_stories_brumadinho_results4_description")}
          </p>

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%]">
              {t("2025_success_stories_brumadinho_reflections")}
            </h4>
          </div>

          <p>
            <span className="font-semibold text-gray-500">
              {t("2025_success_stories_brumadinho_reflections_tagline")}
            </span>
          </p>
          <p>
            <span className="italic pt-2">
              {t("2025_success_stories_brumadinho_reflections_description")}
            </span>
          </p>
          <div className="md:hidden flex flex-row items-center justify-center py-4">
            <a
              href="mailto:contact@leanit101.com"
              className="md:hidden text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
            >
              <span>{t("2025_success_stories_have_a_challenge")}</span>
            </a>
          </div>
        </>
      ),
    },
    {
      title: t("2025_success_stories_vale_title"),
      tagline: t("2025_success_stories_vale_tagline"),
      image: "/images/energygemba.jpg",
      summary: t("2025_success_stories_vale_summary"),
      fullContent: (
        <>
          <div className="flex flex-row justify-between itens-center">
            <div>
              <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-cyan-300 hover:text-pyramid-19 hover:cursor-default">
                <FilterHdrIcon
                  className="m-2"
                  fontSize="large"
                  aria-label="Account Tree Icon"
                />
                {t("2025_success_stories_challenge")}
              </h4>
            </div>
            <div className="hidden md:flex items-center">
              <a
                href="mailto:contact@leanit101.com"
                className="text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
              >
                <span>{t("2025_success_stories_have_a_challenge")}</span>
              </a>
            </div>
          </div>

          <div>
            <span>{t("2025_success_stories_vale_challenge1")}</span>
            <span>{t("2025_success_stories_vale_challenge2")}</span>
          </div>
          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-10 hover:text-orange-600 hover:cursor-default">
              <WbIncandescentIcon className="m-2 rotate-180" fontSize="large" />
              {t("2025_success_stories_approach")}
            </h4>
          </div>

          {[1, 2, 3, 4, 5, 6].map((i) => (
            <p className="ml-4" key={i}>
              <span className="font-semibold text-white">
                {t(`2025_success_stories_vale_approach${i}_title`)}
              </span>
              {t(`2025_success_stories_vale_approach${i}_description`)}
            </p>
          ))}

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-1 hover:text-pyramid-3 hover:cursor-default">
              <VerifiedIcon className="m-2" fontSize="large" />
              {t("2025_success_stories_results")}
            </h4>
          </div>

          {[1, 2, 3, 4].map((i) => (
            <p className="ml-4" key={i}>
              <span className="font-semibold text-white">
                {t(`2025_success_stories_vale_results${i}_title`)}
              </span>
              {t(`2025_success_stories_vale_results${i}_description`)}
            </p>
          ))}
          <br />

          <h4 className="font-bold text-xl p-4">
            {t("2025_success_stories_vale_testimonials")}
          </h4>

          <div className="w-full flex flex-wrap justify-around">
            {testimonialsValeEnergy.map((element, id) => {
              return (
                <div className="md:w-[30%]">
                  <Testimonial
                    key={`testimonial-${id}`}
                    content={element}
                    id={id}
                    className="w-full"
                  />
                </div>
              );
            })}
          </div>
          <div className="md:hidden flex flex-row items-center justify-center py-4">
            <a
              href="mailto:contact@leanit101.com"
              className="md:hidden text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
            >
              <span>{t("2025_success_stories_have_a_challenge")}</span>
            </a>
          </div>
        </>
      ),
    },
    {
      title: t("2025_success_stories_segware_title"),
      tagline: t("2025_success_stories_segware_tagline"),
      image: "/images/segwaregemba.jpg",
      summary: t("2025_success_stories_segware_summary"),
      fullContent: (
        <>
          <div className="flex flex-row justify-between items-center">
            <div>
              <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-cyan-300 hover:text-pyramid-19 hover:cursor-default">
                <FilterHdrIcon
                  className="m-2"
                  fontSize="large"
                  aria-label="Account Tree Icon"
                />
                {t("2025_success_stories_challenge")}
              </h4>
            </div>
            <div className="hidden md:flex items-center">
              <a
                href="mailto:contact@leanit101.com"
                className="text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
              >
                <span>{t("2025_success_stories_have_a_challenge")}</span>
              </a>
            </div>
          </div>

          <div>
            <p>{t("2025_success_stories_segware_challenge")}</p>
          </div>

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-10 hover:text-orange-600 hover:cursor-default">
              <WbIncandescentIcon
                className="m-2 rotate-180"
                fontSize="large"
                aria-label="Account Tree Icon"
              />
              {t("2025_success_stories_approach")}
            </h4>
          </div>

          {[1, 2, 3, 4, 5].map((i) => (
            <p className="ml-4" key={i}>
              <span className="font-semibold text-white">
                {t(`2025_success_stories_segware_approach${i}_title`)}
              </span>
              {t(`2025_success_stories_segware_approach${i}_description`)}
            </p>
          ))}

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-1 hover:text-pyramid-3 hover:cursor-default">
              <VerifiedIcon
                className="m-2"
                fontSize="large"
                aria-label="Account Tree Icon"
              />
              {t("2025_success_stories_results")}
            </h4>
          </div>

          {[1, 2, 3, 4, 5].map((i) => (
            <p className="ml-4" key={i}>
              <span className="font-semibold text-white">
                {t(`2025_success_stories_segware_results${i}_title`)}
              </span>
              {t(`2025_success_stories_segware_results${i}_description`)}
            </p>
          ))}

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%]">
              {t("2025_success_stories_segware_reflections")}
            </h4>
          </div>

          <p>
            <span className="font-semibold text-gray-500">
              {t("2025_success_stories_segware_reflections_tagline")}
            </span>
          </p>
          <p>
            <span className="italic pt-2">
              {t("2025_success_stories_segware_reflections_description")}
            </span>
          </p>
          <div className="md:hidden flex flex-row items-center justify-center py-4">
            <a
              href="mailto:contact@leanit101.com"
              className="md:hidden text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
            >
              <span>{t("2025_success_stories_have_a_challenge")}</span>
            </a>
          </div>
        </>
      ),
    },
    {
      title: t("2025_success_stories_softplan_title"),
      tagline: t("2025_success_stories_softplan_tagline"),
      image: "/images/softplanhoshin.jpg",
      summary: t("2025_success_stories_softplan_summary"),
      fullContent: (
        <>
          <div className="flex flex-row justify-between items-center">
            <div>
              <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-cyan-300 hover:text-pyramid-19 hover:cursor-default">
                <FilterHdrIcon
                  className="m-2"
                  fontSize="large"
                  aria-label="Account Tree Icon"
                />
                {t("2025_success_stories_challenge")}
              </h4>
            </div>
            <div className="hidden md:flex items-center">
              <a
                href="mailto:contact@leanit101.com"
                className="text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
              >
                <span>{t("2025_success_stories_have_a_challenge")}</span>
              </a>
            </div>
          </div>

          <div>
            <p>{t("2025_success_stories_softplan_challenge")}</p>
          </div>

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-10 hover:text-orange-600 hover:cursor-default">
              <WbIncandescentIcon
                className="m-2 rotate-180"
                fontSize="large"
                aria-label="Account Tree Icon"
              />
              {t("2025_success_stories_approach")}
            </h4>
          </div>

          {[1, 2, 3, 4, 5].map((i) => (
            <p className="ml-4" key={i}>
              <span className="font-semibold text-white">
                {t(`2025_success_stories_softplan_approach${i}_title`)}
              </span>
              {t(`2025_success_stories_softplan_approach${i}_description`)}
            </p>
          ))}

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%] text-pyramid-1 hover:text-pyramid-3 hover:cursor-default">
              <VerifiedIcon
                className="m-2"
                fontSize="large"
                aria-label="Account Tree Icon"
              />
              {t("2025_success_stories_results")}
            </h4>
          </div>

          {[1, 2, 3, 4, 5].map((i) => (
            <p className="ml-4" key={i}>
              <span className="font-semibold text-white">
                {t(`2025_success_stories_softplan_results${i}_title`)}
              </span>
              {t(`2025_success_stories_softplan_results${i}_description`)}
            </p>
          ))}

          <div className="flex flex-row items-center">
            <h4 className="font-bold text-xl py-4 transition-transform duration-300 origin-center hover:scale-[110%]">
              {t("2025_success_stories_softplan_testimonials")}
            </h4>
          </div>

          <div className="w-full flex flex-wrap justify-around">
            {testimonialsSoftplan.map((element, id) => {
              return (
                <div className="md:w-[40%]">
                  <Testimonial
                    key={`testimonial-${id}`}
                    content={element}
                    id={id}
                    className="w-full"
                  />
                </div>
              );
            })}
          </div>
          <div className="md:hidden flex flex-row items-center justify-center py-4">
            <a
              href="mailto:contact@leanit101.com"
              className="md:hidden text-pyramid-5 font-semibold hover:text-pyramid-1 transition-all duration-300 origin-center hover:scale-[110%] cursor-pointer underline underline-offset-4"
            >
              <span>{t("2025_success_stories_have_a_challenge")}</span>
            </a>
          </div>
        </>
      ),
    },
  ];

  return (
    <div id="success-stories-section" className="bg-ebony px-4 mb-8 md:px-10">
      <div className="flex flex-col justify-center md:pb-16">
        <span className="text-center md:text-5xl text-2xl font-black break-words p-4">
          {t("2025_success_stories_headline")}
        </span>
        <div className="flex w-full justify-center py-8">
          <hr
            className={`w-[60%] h-[5px] rounded-3xl bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1`}
          />
        </div>
        <span className="md:text-xl text-gray-300 text-base pt-2">
          {t("2025_success_stories_tagline")}
        </span>
      </div>
      <div className="flex flex-col space-y-6">
        {stories.map((story, index) => (
          <SuccessStory
            index={`story-${index}`}
            title={story.title}
            tagline={story.tagline}
            image={story.image}
            summary={story.summary}
            fullContent={story.fullContent}
          />
        ))}
      </div>
    </div>
  );
};

export default SuccessStoriesSection;
