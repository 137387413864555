import "../../../input.css";

const Testimonial = ({ content, id }) => {
  return (
    <div id={`testimonial-${id}`} className="p-3 w-full ">
      <div className="pl-[5px] shadow-black shadow-lg rounded-3xl bg-gradient-to-b from-pyramid-19 via-pyramid-1 to-pyramid-10 w-full drop-shadow">
        <div className="bg-vulcan text-white w-full flex flex-col rounded-r-3xl justify-center items-start p-5 md:p-12">
          <div className="flex flex-row">
            <img
              src={content.imgUrl}
              alt=""
              className="h-20 ws-20 rounded-full outline outline-offset-4 outline-cyan-600"
            />
            <div className="flex flex-col ml-5 text-left justify-center">
              <span className="font-black text-base  md:text-lg">
                {content.name}
              </span>
              <span className="text-cyan-600 text-xs md:text-sm">
                {content.position}
              </span>
              <span className="text-gray-400 font-medium tracking-wider text-base md:text-base">
                {content.company}
              </span>
            </div>
          </div>
          <hr className="flex w-full h-[1px] border-none self-center mb-3 mt-4 bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10" />
          <span className="text-blue-100 font-medium text-sm md:text-left rounded-b-xl flex items-center">
            {content.quote}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
