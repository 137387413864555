import i18n from 'i18next'
import BrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import content from './content.json'

i18n
  .use(BrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    fallbackLng: 'pt-BR',
    keySeparator: true,
    resources: {
      'en-US': {
        translation: content['en-US']
      },
      es: {
        translation: content.es
      },
      'pt-BR': {
        translation: content['pt-BR']
      }
    }
  })

export { i18n }
