import "../../input.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InsightsIcon from "@mui/icons-material/Insights";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SchoolIcon from "@mui/icons-material/School";
import FactoryIcon from "@mui/icons-material/Factory";

import { useTranslation } from "react-i18next";

const Solution = ({ title, description, solutionId }) => (
  <div className="flex flex-row">
    <span className="mx-3 pt-2 font-">
      {solutionId === 0 && (
        <SchoolIcon
          className="text-cyan-300"
          fontSize="large"
          aria-label="School Icon"
        />
      )}
      {solutionId === 1 && (
        <AccountTreeIcon
          className="text-pyramid-15"
          fontSize="large"
          aria-label="Account Tree Icon"
        />
      )}
      {solutionId === 2 && (
        <GroupsIcon
          className="text-pyramid-10"
          fontSize="large"
          aria-label="Groups Icon"
        />
      )}
      {solutionId === 3 && (
        <InsightsIcon
          className="text-pyramid-3"
          fontSize="large"
          aria-label="Insights Icon"
        />
      )}
      {solutionId === 4 && (
        <FactoryIcon
          className="text-pyramid-1"
          fontSize="large"
          aria-label="Insights Icon"
        />
      )}
    </span>
    <div id={solutionId} className="flex flex-col text-left pb-12">
      <span className="md:text-3xl font-bold text-2xl">{title}</span>
      <span className="md:text-xl text-base pt-2">{description}</span>
    </div>
  </div>
);

const TransformativeSolutionsSection = () => {
  const { t } = useTranslation();

  const solutions = [
    {
      title: t("2025_service_transformative_solution1_title"),
      description: t("2025_service_transformative_solution1"),
    },
    {
      title: t("2025_service_transformative_solution2_title"),
      description: t("2025_service_transformative_solution2"),
    },
    {
      title: t("2025_service_transformative_solution3_title"),
      description: t("2025_service_transformative_solution3"),
    },
    {
      title: t("2025_service_transformative_solution4_title"),
      description: t("2025_service_transformative_solution4"),
    },
    {
      title: t("2025_service_transformative_solution5_title"),
      description: t("2025_service_transformative_solution5"),
    },
  ];

  return (
    <div
      id="solutions-section"
      className="flex flex-col justify-center bg-vulcan"
    >
      <span className="text-center md:text-5xl text-2xl font-black break-words p-3">
        {t("2025_service_transformative")}
      </span>
      <div className="flex w-full justify-center pt-12">
        <hr
          className={`w-[60%] h-[5px] rounded-full bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1`}
        />
      </div>

      <section className="md:px-48 flex md:flex-row flex-col bg-vulcan md:py-20 space-x-8 mx-4 ">
        <header className="relative w-full shadow-black shadow-lg md:sticky top-20 h-full text-left drop-shadow-2xl flex flex-col bg-steelGray rounded-3xl md:text-right sm:px-12 sm:py-10 p-8">
          {/* Radial Gradient */}
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              background:
                "radial-gradient(circle at center, rgba(11, 166, 218, 0.5), transparent)",
              zIndex: 0,
              opacity: 1, // Full opacity for the radial gradient
            }}
          ></div>

          {/* Background Image */}
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              backgroundImage: 'url("/images/home/ico-leanit101.png")',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center", // Ensure image is centered both horizontally and vertically
              backgroundSize: "60%", // Image is 90% of container size
              zIndex: 0,
              opacity: 0.1, // Reduced opacity for the image
            }}
          ></div>

          {/* Text Layer */}
          <h1 className="md:text-5xl text-3xl relative z-10">
            <p className="text-white font-thin pt-3 space-y-3">
              {t("2025_service_transformative_description")}
            </p>
            <ArrowForwardIcon className="mt-2 md:rotate-0 rotate-90 md:ml-0 ml-4" />
          </h1>
        </header>

        {/* Solutions List */}
        <div className="flex flex-col w-[85%] md:w-full md:mt-0 mt-28">
          {solutions.map((solution, id) => (
            <Solution
              key={`solution-${id}`}
              solutionId={id}
              title={solution.title}
              description={solution.description}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default TransformativeSolutionsSection;
